import '../less/app.less';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js-color-picker/colors/color-picker.css';

import BpmnModeler from 'bpmn-js/lib/Modeler';
import AlignToOriginModule from '@bpmn-io/align-to-origin';
import AddExporterModule from '@bpmn-io/add-exporter';
import ColorPickerModule from 'bpmn-js-color-picker';
import CliModule from 'bpmn-js-cli';
import GridModule from 'diagram-js-grid';

import {
  CreateAppendAnythingModule
} from 'bpmn-js-create-append-anything';

import $ from 'jquery';

import {
  fileDownload,
  replaceIds,
  track,
  initSentry,
  toggleFullscreen,
  isFullScreenSupported
} from '../shared';

import fileDrop from 'file-drops';

import {
  debounce
} from 'min-dash';

import INITIAL_DIAGRAM from './diagrams/initial.bpmn';


initSentry('bpmn');

const url = new URL(window.location.href);

const presentationMode = url.searchParams.has('pm');


const container = $('#js-drop-zone');
const canvas = $('#js-canvas');

var modeler,
    config;

var fileName = 'diagram.bpmn';

// does the diagram contain unsaved changes
var dirty = false;

function setDirty(newDirty) {
  dirty = newDirty;
}

function checkDirty() {
  if (dirty) {
    return 'This will discard changes you made to the current diagram.';
  }
}

/**
 * Ask for clearing changes (before reload)
 */
window.onbeforeunload = checkDirty;

/**
 * Respond to window resize
 */
window.onresize = debounce(function(e) {
  if (modeler) {
    modeler.get('canvas').resized();
  }
}, 300);

function Config() {

  var storage = window.localStorage || {};

  this.get = function(key) {
    return storage[key];
  };

  this.set = function(key, value) {
    storage[key] = value;
  };
}

var states = [ 'error', 'loading', 'loaded', 'shown', 'intro', 'animate', 'preload' ];


function setStatus(status) {
  $(document.body).removeClass(states.join(' ')).addClass(status);

  setTimeout(function() {
    $(document.body).addClass('animate');
  }, 0);
}

function setError(err) {
  setStatus('error');

  container.find('.error .error-log').val(err.message);

  console.error(err);
}

function showWarnings(warnings) {

  var show = warnings && warnings.length;

  toggleVisible(widgets['import-warnings-alert'], show);

  if (!show) {
    return;
  }

  console.warn('imported with warnings');

  var messages = '';

  warnings.forEach(function(w) {
    console.log(w);
    messages += (w.message + '\n\n');
  });

  var dialog = widgets['import-warnings-dialog'];

  dialog.find('.error-log').val(messages);
}

function toggleVisible(element, show) {
  element && element[show ? 'addClass' : 'removeClass']('open');
}

function openDialog(dialog) {

  var content = dialog.find('.content');

  toggleVisible(dialog, true);

  function stop(e) {
    e.stopPropagation();
  }

  function hide(e) {

    toggleVisible(dialog, false);

    dialog.off('click', hide);
    content.off('click', stop);
  }

  content.on('click', stop);
  dialog.on('click', hide);
}


config = new Config();

modeler = new BpmnModeler({
  container: canvas,
  keyboard: { bindTo: document },
  additionalModules: [
    AlignToOriginModule,
    CliModule,
    AddExporterModule,
    presentationMode ? {} : ColorPickerModule,
    presentationMode ? {} : GridModule,
    presentationMode ? {} : CreateAppendAnythingModule
  ],
  exporter: {
    name: 'bpmn-js (https://demo.bpmn.io)',
    version: process.env.BPMN_JS_VERSION
  }
});

/** INTERACTION TRACKING */

function matches(element, selector) {
  return element.matches(selector) ? element : element.closest(selector);
}

function trackEvent(name) {

  const entryActionSelector = '[data-action], [data-entry-id]';
  const entryHelpSelector = '[data-entry-id] .cmd-change-menu__entry-help';
  const propertiesHelpSelector = '.bio-properties-panel-header-actions [title="Open documentation"]';

  return function(event) {
    const element = event.target;

    try {
      const entryHelpElement = matches(element, entryHelpSelector);
      const propertiesHelpElement = matches(element, propertiesHelpSelector);
      const actualElement = matches(element, entryActionSelector);

      if (propertiesHelpElement) {
        return track('diagram:bpmn:propertiesPanelHelp');
      }

      if (entryHelpElement) {
        return track('diagram:bpmn:entryIdHelp', actualElement.dataset.entryId, name);
      }

      if (actualElement) {
        if (actualElement.dataset.action) {
          track('diagram:bpmn:action', actualElement.dataset.action, name);
        }

        if (actualElement.dataset.entryId) {
          track('diagram:bpmn:entryId', actualElement.dataset.entryId, name);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
}

document.body.addEventListener('click', trackEvent('click'), true);
document.body.addEventListener('dragstart', trackEvent('dragstart'), true);


/** <DRAG_ERROR_HANDLING> */

// forcefully cancel dragging if a drag error occurs
// this prevents unnecessary spam in our log on drag event errors
// also, the errors don't get better after they've been thrown
// for the 100th time.
function cancelDragging() {
  modeler.get('dragging').cancel();
}

modeler.on('dragging.init', function() {
  modeler.on('error', 5000, cancelDragging);

  modeler.once('dragging.cleanup', 50, function() {
    modeler.off(cancelDragging);
  });
});

/** </DRAG_ERROR_HANDLING> */

modeler.on('import.done', function(event) {

  var error = event.error,
      warnings = event.warnings;

  if (error) {
    setError(error);
  } else {

    // async scale to fit-viewport (prevents flickering)
    setTimeout(function() {
      modeler.get('canvas').zoom('fit-viewport');
      setStatus('shown');
    }, 0);

    setStatus('loaded');
  }

  showWarnings(warnings);
});


function openDiagram(xml) {

  var warning = checkDirty();

  if (warning && !window.confirm(warning)) {
    return;
  }

  setStatus('loading');

  modeler.importXML(xml).then(
    () => track('diagram', 'open', 'success'),
    (err) => track('diagram', 'open', 'error')
  );
}

function createDiagram() {

  var warning = checkDirty();

  if (warning && !window.confirm(warning)) {
    return;
  }

  var diagramXML = replaceIds(INITIAL_DIAGRAM);

  modeler.importXML(diagramXML).then(
    () => {
      track('diagram', 'create', 'success');

      // select start event so people can continue to do stuff
      modeler.get('selection').select(
        modeler.get('elementRegistry').get('StartEvent_1')
      );
    },
    (err) => track('diagram', 'create', 'error')
  );
}

function saveSVG() {
  return modeler.saveSVG();
}

function saveDiagram() {
  return modeler.saveXML({ format: true });
}

// file drag / drop ///////////////////////

function openFile(file, callback) {

  // check file api availability
  if (!window.FileReader) {
    return window.alert(
      'Looks like you use an older browser that does not support drag and drop. ' +
      'Try using a modern browser such as Chrome, Firefox or Internet Explorer > 10.');
  }

  // no file chosen
  if (!file) {
    return;
  }

  fileName = file.name;

  var reader = new FileReader();

  reader.onload = function(e) {

    var xml = e.target.result;

    callback(xml);
  };

  reader.readAsText(file);
}

document.body.addEventListener('dragover', fileDrop('Open BPMN diagram', function(files) {

  // files = [ { name, contents }, ... ]

  const file = files[0];

  if (file) {
    track('diagram', 'open-drop');
    openDiagram(file.contents);
  }
}), false);


var fileInput = $('<input type="file" />').appendTo(document.body).css({
  width: 1,
  height: 1,
  display: 'none',
  overflow: 'hidden'
}).on('change', function(e) {
  track('diagram', 'open-dialog');
  openFile(e.target.files[0], openDiagram);
});

var widgets = {};

function hideUndoAlert(e) {
  toggleVisible(widgets['undo-redo-alert'], false);

  if (config.set('hide-alert', 'yes')) {
    return;
  }
}

function updateUndoAlert() {
  if (config.get('hide-alert')) {
    return;
  }

  var commandStack = modeler.get('commandStack');

  var idx = commandStack._stackIdx;

  toggleVisible(widgets['undo-redo-alert'], idx >= 0);
}

function showEditingTools() {
  widgets['editing-tools'].show();

  // disable full screen button if not supported
  if (!isFullScreenSupported()) {
    const fullScreenButton = document.querySelector('li:has(button[title="Toggle Fullscreen"])');
    fullScreenButton.style.display = 'none';
  }
}

function undo(e) {
  modeler.get('commandStack').undo();
}

function downloadDiagram(e) {

  e.preventDefault();

  saveDiagram().then(
    (result) => {
      fileDownload(result.xml, fileName, 'application/xml');

      setDirty(false);
    }
  ).catch(
    (err) => console.error(err)
  );
}

function openLocalDiagram() {
  var input = $(fileInput);

  // clear input so that previously selected file can be reopened
  input.val('');
  input.trigger('click');
}

var actions = {

  'bio.toggleFullscreen': function() {
    var elem = document.querySelector('html');
    toggleFullscreen(elem);
  },
  'bio.createNew': createDiagram,
  'bio.openLocal': openLocalDiagram,
  'bio.zoomReset': function() {
    modeler.get('zoomScroll').reset();
  },
  'bio.zoomIn': function(e) {
    modeler.get('zoomScroll').stepZoom(1);
  },
  'bio.zoomOut': function(e) {
    modeler.get('zoomScroll').stepZoom(-1);
  },
  'bio.showKeyboard': function(e) {

    var dialog = widgets['keybindings-dialog'];

    var platform = navigator.platform;

    if (/Mac/.test(platform)) {
      dialog.find('.bindings-default').remove();
    } else {
      dialog.find('.bindings-mac').remove();
    }

    openDialog(dialog);
  },
  'bio.showAbout': function(e) {
    openDialog(widgets['about-dialog']);
  },
  'bio.undo': undo,
  'bio.hideUndoAlert': hideUndoAlert,
  'bio.clearImportDetails': function(e) {
    showWarnings(null);
  },
  'bio.showImportDetails': function(e) {
    openDialog(widgets['import-warnings-dialog']);
  }
};

$(function() {

  // initialize existing widgets defined in
  // <div jswidget="nameOfWidget" />
  //
  // after this step we can use a widget via
  // widgets['nameOfWidget']
  $('[jswidget]').each(function() {
    var element = $(this),
        jswidget = element.attr('jswidget');

    widgets[jswidget] = element;
  });

  // attach all the actions defined via
  // <div jsaction="event:actionName" />

  function parseActionAttr(element) {

    var match = $(element).attr('jsaction').split(/:(.+$)/, 2);

    return {
      event: match[0], // click
      name: match[1] // bio.fooBar
    };
  }

  function actionListener(event) {
    var jsaction = parseActionAttr($(this));

    var name = jsaction.name,
        action = actions[name];

    if (!action) {
      throw new Error('no action <' + name + '> defined');
    }

    event.preventDefault();

    action(event);
  }


  var delegates = {};

  $('[jsaction]').each(function() {

    var jsaction = parseActionAttr($(this));

    var event = jsaction.event;

    if (!delegates[event]) {
      $(document.body).on(event, '[jsaction]', actionListener);
      delegates[event] = true;
    }


    var name = jsaction.name,
        handler = actions[name];

    if (!handler) {
      throw new Error('no action <' + name + '> defined');
    }
  });

  $(widgets['downloadDiagram']).click(downloadDiagram);

  $(widgets['downloadSVG']).click(function(e) {

    e.preventDefault();

    saveSVG().then(
      (result) => fileDownload(result.svg, 'diagram.svg', 'image/svg+xml')
    ).catch(err => console.error(err));
  });

  function modelUpdate() {
    setDirty(true);

    updateUndoAlert();
  }

  function importSuccess() {
    setDirty(false);

    updateUndoAlert();
    showEditingTools();
  }

  modeler.on('commandStack.changed', modelUpdate);
  modeler.on('import.done', function(event) {
    if (!event.error) {
      importSuccess();
    }
  });

  // add keyboard shortcuts
  document.body.addEventListener('keydown', function(event) {
    if (event.code === 'KeyS' && (event.metaKey || event.ctrlKey)) {
      downloadDiagram(event);
    }

    if (event.code === 'KeyO' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      openLocalDiagram();
    }
  });

});


// export some globals for easier debugging
global.bpmnio = {
  modeler: modeler,
  openDiagram: openDiagram
};


/**
 * Open or load diagram
 */
(function() {

  var href = window.location.href;

  var loader = null;

  // Open diagram via /s/:diagramName

  var openRemoteMatch = /\/s\/([^?]*)/.exec(href);

  if (openRemoteMatch) {
    loader = function() {

      var diagramUrl = '/bpmn/diagrams/' + openRemoteMatch[1] + '.bpmn';

      $.get(diagramUrl, { dataType: 'text' }).then(
        openDiagram,
        function(response) {
          var err;

          if (response.status === 404) {
            err = new Error('The diagram does not exist (code=404)');
          } else {
            err = new Error('Failed to load diagram (code=' + response.code + ')');
          }

          setError(err);
        }
      );

    };

  }

  // Create a new diagram on /new
  else if (/\/new(\?.*)?/.test(href)) {
    loader = createDiagram;

  }

  // Load diagram via localStorage, if specified.
  else if (config.get('save')) {

    var diagramXML = config.get('save.diagramXML');

    if (diagramXML) {
      loader = function() {
        openDiagram(diagramXML);
      };
    }
  }

  if (loader) {
    setStatus('loading');
    setTimeout(loader, 100);
  } else {
    setStatus('intro');
  }
})();
